import { createContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet, useParams } from 'react-router-dom';
import { CourseDetailDto, TrainingApiApi } from '../../@api/DigiVet';
import { ApiWrapper } from '../../common/ApiWrapper';

export const CoursesContext = createContext<CourseDetailDto[]>([]);

function CoursesProvider() {
  const auth = useAuth();
  const params = useParams();
  const [courseAssets, setCourseAssets] = useState<CourseDetailDto[]>([]);

  useEffect(() => {
    const loadProfile = async () => {
      const trainingApi = new ApiWrapper<TrainingApiApi>(auth, TrainingApiApi);
      await trainingApi.load(async () => {
        const response = await trainingApi.getApiInstance().apiTrainingApiCourseDetailGet(params.courseId);
        setCourseAssets(response.data);
      });
    };
    loadProfile();
  }, []);

  return (
    <CoursesContext.Provider value={courseAssets}>
      <Outlet />
    </CoursesContext.Provider>
  );
}

export default CoursesProvider;

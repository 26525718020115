import { Outlet } from 'react-router-dom';
import { Container } from '@mantine/core';
import { FooterCentered } from './Footer';
import Logout from './LogOut';

const Layout = () => {
  return (
    <>
      <Container size="xs" px="xs">
        <Logout />
        <Outlet />
        <FooterCentered />
      </Container>
    </>
  );
};

export default Layout;

import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CoursesContext } from '../../components/E-Learning/CoursesProvider';
import QuizCard from '../../components/E-Learning/Cards/QuizCard';

const SectionQuiz = () => {
  const courseAssets = useContext(CoursesContext);
  const params = useParams();
  const asset = courseAssets.at(Number(params.sectionId))?.quizUrl;
  return (
    <>
      <QuizCard url={asset ?? ''} />
    </>
  );
};

export default SectionQuiz;

import { createStyles, Group, ActionIcon, Text, Stack, Image } from '@mantine/core';
import profile from '../assets/Footer/profile.png';
import mobility from '../assets/Footer/mobility.png';
import challenges from '../assets/Footer/challenge.png';
import learning from '../assets/Footer/learning.png';
import { Link } from 'react-router-dom';

const useStyles = createStyles(theme => ({
  footer: {
    fontSize: '20px',
    color: 'white',
    background: 'white',
    borderTop: `1px solid ${theme.colors.gray[3]}`,
    textAlign: 'center',
    position: 'fixed',
    left: '0px',
    bottom: '0px',
    height: '70px',
    width: '100%',
    marginTop: 120,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },
  text: {
    marginTop: -18,
    color: 'black',
    fontSize: '13px',
  },
  phantom: {
    display: 'block',
    height: '80px',
    width: '100%',
  },
}));

export function FooterCentered() {
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.phantom} />
      <div className={classes.footer}>
        {
          <Group className={classes.inner}>
            <ActionIcon>
              <Stack>
                <Link to={'/'}>
                  <Image width={'110%'} src={profile} alt="" />
                </Link>
                <Text className={classes.text}>Profile</Text>
              </Stack>
            </ActionIcon>

            <ActionIcon>
              <Stack>
                <Link to={'/mobility'}>
                  <Image src={mobility} alt="" />
                </Link>
                <Text className={classes.text}>Mobility</Text>
              </Stack>
            </ActionIcon>

            <ActionIcon>
              <Stack>
                <Link to={'/challenges'}>
                  <Image width={'85%'} src={challenges} alt="" />
                </Link>
                <Text className={classes.text}>Challenges</Text>
              </Stack>
            </ActionIcon>

            <ActionIcon>
              <Stack>
                <Link to={'/courses'}>
                  <Image width={'93%'} src={learning} alt="" />
                </Link>
                <Text className={classes.text}>Materials</Text>
              </Stack>
            </ActionIcon>
          </Group>
        }
      </div>
    </div>
  );
}

import { Paper, Container, Title, Text, Button } from '@mantine/core';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';

const Login: React.FC = () => {
  const auth = useAuth();

  return auth.isAuthenticated ? (
    <Navigate to="/" replace />
  ) : (
    <Container size={420} my={40}>
      <Title align="center">Welcome to DigiVET!</Title>
      <Paper radius="md" p="xl" withBorder mt={30}>
        <Text>To use the DigiVET app you need to sign in first.</Text>
        <Button fullWidth mt="xl" onClick={() => auth.signinRedirect()}>
          Sign in
        </Button>
      </Paper>
    </Container>
  );
};

export default Login;

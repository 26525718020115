import { Card, Image, Text, Badge, Group, createStyles, ActionIcon } from '@mantine/core';
import group from '../../assets/Challenge/group.svg';
import { Trophy } from 'tabler-icons-react';
import DigiVetButton from '../DigiVetButton';
import { ChallengeListDto } from '../../@api/DigiVet';
import ikea from '../../assets/Challenge/challenge.png';

const useStyles = createStyles(theme => ({
  deadline: {
    position: 'absolute',
    top: theme.spacing.lg,
    left: theme.spacing.xs,
    pointerEvents: 'none',
    backgroundColor: theme.colors.badgeColor,
    color: 'black',
    textTransform: 'capitalize',
  },
  applicants: {
    position: 'absolute',
    left: theme.spacing.xs,
    bottom: theme.spacing.xl + 32,
    backgroundColor: theme.colors.badgeColor,
    color: 'black',
    pointerEvents: 'none',
    textTransform: 'capitalize',
  },
  chosen: {
    position: 'absolute',
    right: theme.spacing.xs,
    bottom: theme.spacing.xl + 32,
    backgroundColor: theme.colors.badgeColor,
    color: 'black',
    pointerEvents: 'none',
    textTransform: 'capitalize',
  },
  grayscale: {
    filter: 'grayscale(100%)',
  },
  card: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
}));

const Challenge = (prop: ChallengeListDto) => {
  const { classes } = useStyles();

  const route = '/challenges/' + prop.id;
  const TrophyIcon = (
    <ActionIcon size="xs" color="dark" variant="transparent">
      <Trophy size={10} />
    </ActionIcon>
  );
  return (
    <Card className={classes.card} mb={20}>
      <Card.Section sx={{ position: 'relative' }}>
        {prop.isClosed ? (
          <Image className={classes.grayscale} src={ikea} width="100%" radius={'lg'} alt="Ikea" />
        ) : (
          <a href={route}>
            <Image src={ikea} width="100%" radius={'lg'} alt="Ikea" />
          </a>
        )}
        <Badge size="md" className={classes.deadline}>
          Deadline: {' ' + new Date(prop?.deadline ?? '').toLocaleDateString()}
        </Badge>
        <Badge size="md" className={classes.applicants}>
          {prop.applicants} applicants
        </Badge>
        {prop.isEvaluationFinished && (
          <Badge size="md" rightSection={TrophyIcon} className={classes.chosen}>
            Winners are chosen
          </Badge>
        )}
        <Group position="apart" spacing={0} mt={5}>
          <Text color={'white'} size="sm">
            {prop.titleChallenge}
          </Text>
          <Group>
            {prop.isApplied && !prop.isUnderEvaluation && !prop.isEvaluationFinished && (
              <>
                <Image ml={8} width={30} src={group}></Image>
                <DigiVetButton isOutline={true} size="xs" content="You applied"></DigiVetButton>
              </>
            )}
            {prop.isUnderEvaluation && !prop.isClosed && prop.isUnderEvaluation && !prop.isEvaluationFinished && (
              <>
                <DigiVetButton isOutline={true} size="xs" content="Under Evaluation"></DigiVetButton>
              </>
            )}
          </Group>
        </Group>
      </Card.Section>
    </Card>
  );
};

export default Challenge;

import { Box, Group, Text } from '@mantine/core';
import { Video, File } from 'tabler-icons-react';
import { CourseAssetDto } from '../../@api/DigiVet';
import { Link } from 'react-router-dom';

interface Prop extends CourseAssetDto {
  sectionId: number;
  assetId: number;
  isQuiz: boolean;
}

export default function SubCourse(prop: Prop) {
  const isVideo = prop.path?.endsWith('.mp4') || prop.url?.toLowerCase().includes('youtube');
  if (prop.isQuiz) {
    return (
      <>
        <Box
          sx={theme => ({
            background: 'white',
            textAlign: 'center',
            width: '310px',
            marginTop: theme.spacing.xl,
            padding: theme.spacing.xl,
            borderRadius: theme.radius.xl,
          })}
        >
          <Group position="apart" mt="md" mb="xs">
            <Link to={'/courses/courseView/quiz/' + prop.sectionId}>
              <Text size="xl" style={{ color: 'black', textDecoration: 'none' }}>
                {prop.title}
              </Text>
            </Link>
            <File size={40} />
          </Group>
          {/* <Text color={'black'} size="sm">
            description
          </Text> */}
        </Box>
      </>
    );
  }
  return (
    <>
      <Box
        sx={theme => ({
          width: '310px',
          background: 'white',
          textAlign: 'center',
          marginTop: theme.spacing.xl,
          padding: theme.spacing.xl,
          borderRadius: theme.radius.xl,
        })}
      >
        <Group position="apart" mb="xs">
          <Link to={'/courses/courseView/' + prop.sectionId + '/' + prop.assetId}>
            <Text size="xl" style={{ color: 'black', textDecoration: 'none' }}>
              {prop.title}
            </Text>
          </Link>
          {isVideo ? <Video size={40} /> : <File size={40} />}
        </Group>
        <Text align="left" color={'black'} size="sm">
          {prop.description}
        </Text>
      </Box>
    </>
  );
}

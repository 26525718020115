import { Card, Text, Image } from '@mantine/core';

type Prop = {
  path: string;
  title: string;
  description: string;
};
const ImageCard = (prop: Prop) => {
  return (
    <>
      <Card shadow="sm" p="lg" radius="md" withBorder mt={30}>
        <Card.Section mt={5}>
          <Image src={prop.path} />
        </Card.Section>
        <Text weight={800}>{prop.title}</Text>
        <Text size="sm" color="dark">
          {prop.description}
        </Text>
      </Card>
    </>
  );
};
export default ImageCard;

import trophy from '../../assets/Challenge/trophy.svg';
import group from '../../assets/Challenge/group.svg';
import hurray from '../../assets/Challenge/hurray.png';
import time from '../../assets/Challenge/time.svg';
import upload from '../../assets/Challenge/upload.svg';
import remove from '../../assets/Challenge/remove.svg';
import ikea from '../../assets/Challenge/challenge.png';
import back from '../../assets/back.png';
import {
  Image,
  Title,
  Group,
  Text,
  Divider,
  Center,
  List,
  ActionIcon,
  Stack,
  Affix,
  TextInput,
  Loader,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { useEffect, useState } from 'react';
import DigiVetButton from '../../components/DigiVetButton';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import ChallengeSubmitModal from '../../components/Challenges/ChallengeSubmitModal';
import { Link, useParams } from 'react-router-dom';
import { ChallengeApplyDto, ChallengeDetailDto, ChallengesApiApi } from '../../@api/DigiVet';
import { useAuth } from 'react-oidc-context';
import { ApiWrapper } from '../../common/ApiWrapper';
import deadline from '../../assets/Challenge/deadline.svg';
import { CircleCheck } from 'tabler-icons-react';

const ChallengeDetail = () => {
  const [isApplied, setIsApplied] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [appliedWithTeam, setAppliedWithTeam] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const auth = useAuth();
  const params = useParams();
  const [loadState, setLoadState] = useState('loading');

  const [challengeDetailDto, setChallengeDto] = useState<ChallengeDetailDto>();
  const [challengeApply, setChallengeApply] = useState<ChallengeApplyDto>({
    challengeId: params.challengeId,
    teammates: '',
  });

  useEffect(() => {
    const loadExistedData = async () => {
      const challengesApi = new ApiWrapper<ChallengesApiApi>(auth, ChallengesApiApi);
      challengesApi
        .load(async () => {
          var response = await challengesApi.getApiInstance().apiChallengesChallengeDetailGet(params.challengeId);
          setChallengeDto(response.data ?? []);
          setIsApplied(response.data.isApplied ?? false);
          setIsSubmitted(response.data.isUnderEvaluation ?? false);
        })
        .then(() => setLoadState('loaded'));
    };
    loadExistedData();
  }, [isApplied, isSubmitted]);

  if (loadState === 'loading') {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <ModalsProvider>
      <>
        <Affix position={{ top: 10, left: 20 }}>
          <ActionIcon<typeof Link> component={Link} to="/Challenges">
            <Image src={back} />
          </ActionIcon>
        </Affix>

        <Image src={ikea} alt={challengeDetailDto?.title ?? 'ERROR'} />
        <>
          <Title color={'white'} mt={35} align="center" order={3}>
            {challengeDetailDto?.title}
          </Title>
          <Title color={'white'} mt={20} align="center" order={3}>
            {challengeDetailDto?.hostingCompanyName}
          </Title>
          <Text color={'white'} mt={30}>
            {challengeDetailDto?.shortDescription}
          </Text>
          <Divider mt={30} />
          <Text color={'white'} mt={30} lineClamp={5}>
            {challengeDetailDto?.longDescription}
          </Text>
          <Group mt={30}>
            <Image width={30} src={deadline}></Image>
            <Text color={'white'}>
              Deadline:
              {' ' + new Date(challengeDetailDto?.deadline ?? '').toLocaleDateString()}
            </Text>
          </Group>
          <Group mt={20}>
            <Image width={30} src={trophy}></Image>
            <Text color={'white'}> Possible Reward: {challengeDetailDto?.possibleReward}</Text>
          </Group>
          {challengeDetailDto?.isApplied &&
            !challengeDetailDto.isTeamFormed &&
            !isSubmitted &&
            !challengeDetailDto.isEvaluationFinished && (
              <Group mt={20}>
                <Image width={30} src={time}></Image>
                <Text color={'white'} size={'sm'}>
                  {' '}
                  Your team formation is under arrangement
                </Text>
              </Group>
            )}
          {isSubmitted && !challengeDetailDto?.isClosed && !challengeDetailDto?.isEvaluationFinished && (
            <Group mt={20} align={'center'}>
              <Image width={30} src={time} />
              <Text color={'white'} size={'sm'}>
                {' '}
                Your project in under evaluation
              </Text>
            </Group>
          )}
          {challengeDetailDto?.isTeamFormed && !isSubmitted && !challengeDetailDto.isEvaluationFinished && (
            <>
              <Group mt={20}>
                <Image width={30} src={group}></Image>
                <Text color={'white'}> Your team members are:</Text>
              </Group>
              <List ml={40} mt={15} listStyleType={'none'}>
                {challengeDetailDto?.teammates.map((item, i) => (
                  <List.Item key={i}>{item}</List.Item>
                ))}
              </List>
              <Group position="center" mt={10}>
                <Dropzone
                  mt={20}
                  onDrop={acceptedFiles => {
                    const previousState = files;
                    const nextState = [...previousState, ...acceptedFiles];
                    setFiles(nextState);
                  }}
                  radius="md"
                  onReject={files => console.log('rejected files', files)}
                  accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.doc, MIME_TYPES.mp4, MIME_TYPES.pdf]}
                >
                  {
                    <Stack align={'stretch'}>
                      <Center>
                        <Image width={30} src={upload} />
                      </Center>
                      <Text align="center" size="sm" mt="xs" color="dimmed">
                        Please, press here if you want to attach your CV in pdf format
                      </Text>
                    </Stack>
                  }
                </Dropzone>
                <Group position="center">
                  {files.map((value, index) => (
                    <Group key={value.name + index}>
                      <Text>{value.name}</Text>
                      <ActionIcon
                        onClick={() => {
                          setFiles(prevFiles => prevFiles.filter((file, idx) => idx !== index));
                        }}
                      >
                        <Image src={remove}></Image>
                      </ActionIcon>
                    </Group>
                  ))}
                </Group>
              </Group>
              <Center mt={20}>
                <ChallengeSubmitModal
                  setIsSubmitted={setIsSubmitted}
                  challengeId={params.challengeId ?? ' '}
                  files={files}
                />
              </Center>
            </>
          )}
          <Center mt={45}>
            {!challengeDetailDto?.isTeamFormed &&
              !challengeDetailDto?.isEvaluationFinished &&
              (!challengeDetailDto?.isApplied ? (
                <>
                  {!appliedWithTeam ? (
                    <Group>
                      <DigiVetButton
                        content="Apply with team"
                        onClickHandler={() => {
                          const challengesApi = new ApiWrapper<ChallengesApiApi>(auth, ChallengesApiApi);
                          challengesApi.load(async () => {
                            setAppliedWithTeam(true);
                          });
                        }}
                      />
                      <DigiVetButton
                        content="Apply without team"
                        onClickHandler={() => {
                          const challengesApi = new ApiWrapper<ChallengesApiApi>(auth, ChallengesApiApi);
                          challengesApi.load(async () => {
                            await challengesApi.getApiInstance().apiChallengesApplyPost(challengeApply);
                            setIsApplied(true);
                          });
                        }}
                      />
                    </Group>
                  ) : (
                    <Group>
                      <TextInput
                        placeholder={'Teammates name'}
                        onChange={event => {
                          setChallengeApply(currentValue => ({ ...currentValue, teammates: event.target?.value }));
                        }}
                      />
                      <DigiVetButton
                        content={'Apply'}
                        onClickHandler={() => {
                          const challengesApi = new ApiWrapper<ChallengesApiApi>(auth, ChallengesApiApi);
                          challengesApi.load(async () => {
                            await challengesApi.getApiInstance().apiChallengesApplyPost(challengeApply);
                            setIsApplied(true);
                          });
                        }}
                      ></DigiVetButton>
                    </Group>
                  )}
                </>
              ) : (
                !isSubmitted && <DigiVetButton content="You applied" isOutline></DigiVetButton>
              ))}
            {isSubmitted && !challengeDetailDto?.isClosed && !challengeDetailDto?.isEvaluationFinished && (
              <Group position="center">
                <CircleCheck color="#FFFFFF" width={30}></CircleCheck>
                <Text color={'white'} weight={'bold'}>
                  You successfully submitted you project!
                </Text>
              </Group>
            )}
            {challengeDetailDto?.isApplied &&
              challengeDetailDto?.isEvaluationFinished &&
              (challengeDetailDto.isCurrentUserWinner ? (
                <Stack>
                  <Group>
                    <Title color={'white'}>Congratulations, you have won the challenge</Title>
                    <Image width={30} src={hurray}></Image>
                  </Group>
                  <Text>{challengeDetailDto.evaluationText} </Text>
                </Stack>
              ) : (
                <Stack>
                  <Title>We regret to inform you that you were not chosen as a winner</Title>
                  <Text>{challengeDetailDto.evaluationText} </Text>
                </Stack>
              ))}
          </Center>
        </>
      </>
    </ModalsProvider>
  );
};

export default ChallengeDetail;

export const dropzoneChildren = () => (
  <Group position="center">
    <div>
      <Text color={'white'} size="lg" inline>
        Drag Files here or click to select files
      </Text>
      <Text color={'white'} size="sm" inline mt={7}>
        Attach as many files as you like, each file should not exceed 5mb
      </Text>
    </div>
  </Group>
);

import { Center, Divider, Grid, Loader, Text, Image } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { MyMobilityApiApi, MyMobilityDto } from '../../@api/DigiVet';
import { ApiWrapper } from '../../common/ApiWrapper';
import LabelAndValue from '../../components/Profile/LabelAndValue';
import DigiVetLogo from '../../assets/E-Learning/digivet.png';

const MyMobility = () => {
  const [mobilityData, setMobilityData] = useState<MyMobilityDto>();
  const auth = useAuth();
  const [loadState, setLoadState] = useState('loading');
  useEffect(() => {
    const loadProfile = async () => {
      const myMobilityApi = new ApiWrapper<MyMobilityApiApi>(auth, MyMobilityApiApi);
      await myMobilityApi
        .load(async () => {
          const mobility = await myMobilityApi.getApiInstance().apiMyMobilityGet();
          setMobilityData({
            accommodation: mobility.data.accommodation,
            dressCode: mobility.data.dressCode,
            equipments: mobility.data.equipments,
            hostingCompany: mobility.data.hostingCompany,
            name: mobility.data.name,
            tutorsContact: mobility.data.tutorsContact,
            workingTime: mobility.data.workingTime,
          });
        })
        .then(() => setLoadState('loaded'));
    };
    loadProfile();
  }, []);

  if (loadState === 'loading') {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  return (
    <>
      <Grid mb={20}>
        <Grid.Col span={5}>
          <Text
            sx={{
              fontSize: '25px',
            }}
            color={'white'}
            mt={'10px'}
          >
            My Mobility
          </Text>
        </Grid.Col>
        <Grid.Col span={5} offset={2}>
          <Image width={'75%'} src={DigiVetLogo} />
        </Grid.Col>
      </Grid>

      <Text color={'white'} size="xl" weight={600} mb={5}>
        {mobilityData?.name}
      </Text>
      <Divider mb={10} />

      <LabelAndValue label="Accommodation" values={[mobilityData?.accommodation?.[0] ?? ' ']} />
      <Divider mb={10} />

      <LabelAndValue label="Hosting company" values={[mobilityData?.hostingCompany?.[0] ?? ' ']} />
      <Divider mb={10} />

      <LabelAndValue
        label="Tutor's contact"
        values={[mobilityData?.tutorsContact?.[0]?.name ?? ' ', mobilityData?.tutorsContact?.[0]?.phoneNumber ?? ' ']}
      />
      <Divider mb={10} />

      <LabelAndValue label="Working time" values={[mobilityData?.workingTime?.[0] ?? ' ']} />
      <Divider mb={10} />

      <LabelAndValue label="Dresscode" values={[mobilityData?.dressCode?.[0] ?? ' ']} />
      <Divider mb={10} />

      <LabelAndValue label="Equipments" values={[mobilityData?.equipments?.[0] ?? ' ']} />
    </>
  );
};

export default MyMobility;

export const GetLanguageTextByCode = (code: string): string => {
  return LanguagesDictionary.find(x => x.code == code)?.text ?? ' ';
};

export const GetCodeByLanguageText = (language: string): string => {
  return LanguagesDictionary.find(x => x.text == language)?.code ?? ' ';
};

type languageType = {
  text: string;
  code: string;
};

const LanguagesDictionary: languageType[] = [
  { text: 'Abkhazian', code: 'ab' },
  { text: 'Afar', code: 'aa' },
  { text: 'Afrikaans', code: 'af' },
  { text: 'Akan', code: 'ak' },
  { text: 'Albanian', code: 'sq' },
  { text: 'Amharic', code: 'am' },
  { text: 'Arabic', code: 'ar' },
  { text: 'Aragonese', code: 'an' },
  { text: 'Armenian', code: 'hy' },
  { text: 'Assamese', code: 'as' },
  { text: 'Avaric', code: 'av' },
  { text: 'Avestan', code: 'ae' },
  { text: 'Aymara', code: 'ay' },
  { text: 'Azerbaijani', code: 'az' },
  { text: 'Bambara', code: 'bm' },
  { text: 'Bashkir', code: 'ba' },
  { text: 'Basque', code: 'eu' },
  { text: 'Belarusian', code: 'be' },
  { text: 'Bengali (Bangla)', code: 'bn' },
  { text: 'Bihari', code: 'bh' },
  { text: 'Bislama', code: 'bi' },
  { text: 'Bosnian', code: 'bs' },
  { text: 'Breton', code: 'br' },
  { text: 'Bulgarian', code: 'bg' },
  { text: 'Burmese', code: 'my' },
  { text: 'Catalan', code: 'ca' },
  { text: 'Chamorro', code: 'ch' },
  { text: 'Chechen', code: 'ce' },
  { text: 'Chichewa, Chewaanja', code: 'ny' },
  { text: 'Chinese', code: 'zh' },
  { text: 'Chinese (Simplifi},)', code: 'zh-Hans' },
  { text: 'Chinese (Tracode:ditio},l)', code: 'zh-Hant' },
  { text: 'Chuvash', code: 'cv' },
  { text: 'Cornish', code: 'kw' },
  { text: 'Corsican', code: 'co' },
  { text: 'Cree', code: 'cr' },
  { text: 'Croatian', code: 'hr' },
  { text: 'Czech', code: 'cs' },
  { text: 'Danish', code: 'da' },
  { text: 'Divehi, Dhivehi, Maldivian', code: 'dv' },
  { text: 'Dutch', code: 'nl' },
  { text: 'Dzongkha', code: 'dz' },
  { text: 'English', code: 'en' },
  { text: 'Esperanto', code: 'eo' },
  { text: 'Estonian', code: 'et' },
  { text: 'Ewe', code: 'ee' },
  { text: 'Faroese', code: 'fo' },
  { text: 'Fijian', code: 'fj' },
  { text: 'Finnish', code: 'fi' },
  { text: 'French', code: 'fr' },
  { text: 'Fula, Fulah, Pula, Pular', code: 'ff' },
  { text: 'Galician', code: 'gl' },
  { text: 'Gaelic (Scottish)', code: 'gd' },
  { text: 'Gaelic (Manxcode:)', code: 'gv' },
  { text: 'Georgian', code: 'ka' },
  { text: 'German', code: 'de' },
  { text: 'Greek', code: 'el' },
  { text: 'Greenlandic', code: 'kl' },
  { text: 'Guarani', code: 'gn' },
  { text: 'Gujarati', code: 'gu' },
  { text: 'Haitian Creole', code: 'ht' },
  { text: 'Hausa', code: 'ha' },
  { text: 'Hebrew', code: 'he' },
  { text: 'Herero', code: 'hz' },
  { text: 'Hindi', code: 'hi' },
  { text: 'Hiri Motu', code: 'ho' },
  { text: 'Hungarian', code: 'hu' },
  { text: 'Icelandic', code: 'is' },
  { text: 'Ido', code: 'io' },
  { text: 'Igbo', code: 'ig' },
  { text: 'Indonesian', code: 'id, in' },
  { text: 'Interlingua', code: 'ia' },
  { text: 'Interlingue', code: 'ie' },
  { text: 'Inuktitut', code: 'iu' },
  { text: 'Inupiak', code: 'ik' },
  { text: 'Irish', code: 'ga' },
  { text: 'Italian', code: 'it' },
  { text: 'Japanese', code: 'ja' },
  { text: 'Javanese', code: 'jv' },
  { text: 'Kalaallisut, Greeandic', code: 'kl' },
  { text: 'Kannada', code: 'kn' },
  { text: 'Kanuri', code: 'kr' },
  { text: 'Kashmiri', code: 'ks' },
  { text: 'Kazakh', code: 'kk' },
  { text: 'Khmer', code: 'km' },
  { text: 'Kikuyu', code: 'ki' },
  { text: 'Kinyarwanda (Rwan},)', code: 'rw' },
  { text: 'Kirundi', code: 'rn' },
  { text: 'Kyrgyz', code: 'ky' },
  { text: 'Komi', code: 'kv' },
  { text: 'Kongo', code: 'kg' },
  { text: 'Korean', code: 'ko' },
  { text: 'Kurdish', code: 'ku' },
  { text: 'Kwanyama', code: 'kj' },
  { text: 'Lao', code: 'lo' },
  { text: 'Latin', code: 'la' },
  { text: 'Latvian (Lettish)', code: 'lv' },
  { text: 'Limburgish (Limburger)', code: 'li' },
  { text: 'Lingala', code: 'ln' },
  { text: 'Lithuanian', code: 'lt' },
  { text: 'Luga-Katanga', code: 'lu' },
  { text: 'Luganda, Ganda', code: 'lg' },
  { text: 'Luxembourgish', code: 'lb' },
  { text: 'Manx', code: 'gv' },
  { text: 'Macedonian', code: 'mk' },
  { text: 'Malagasy', code: 'mg' },
  { text: 'Malay', code: 'ms' },
  { text: 'Malayalam', code: 'ml' },
  { text: 'Maltese', code: 'mt' },
  { text: 'Maori', code: 'mi' },
  { text: 'Marathi', code: 'mr' },
  { text: 'Marshallese', code: 'mh' },
  { text: 'Moldavian', code: 'mo' },
  { text: 'Mongolian', code: 'mn' },
  { text: 'Nauru', code: 'na' },
  { text: 'Navajo', code: 'nv' },
  { text: 'Ndonga', code: 'ng' },
  { text: 'Northern Ndebele', code: 'nd' },
  { text: 'Nepali', code: 'ne' },
  { text: 'Norwegian', code: 'no' },
  { text: 'Norwegian bokmål', code: 'nb' },
  { text: 'Norwegian nynorsk', code: 'nn' },
  { text: 'Nuosu', code: 'ii' },
  { text: 'Occitan', code: 'oc' },
  { text: 'Ojibwe', code: 'oj' },
  { text: 'Old Church Slavon, Old Bulgarian', code: 'cu' },
  { text: 'Oriya', code: 'or' },
  { text: 'Oromo (Afaan Oromo)', code: 'om' },
  { text: 'Ossetian', code: 'os' },
  { text: 'Pāli', code: 'pi' },
  { text: 'Pashto, Pushto', code: 'ps' },
  { text: 'Persian (Farsi)', code: 'fa' },
  { text: 'Polish', code: 'pl' },
  { text: 'Portuguese', code: 'pt' },
  { text: 'Punjabi (Eastern)', code: 'pa' },
  { text: 'Quechua', code: 'qu' },
  { text: 'Romansh', code: 'rm' },
  { text: 'Romanian', code: 'ro' },
  { text: 'Russian', code: 'ru' },
  { text: 'Sami', code: 'se' },
  { text: 'Samoan', code: 'sm' },
  { text: 'Sango', code: 'sg' },
  { text: 'Sanskrit', code: 'sa' },
  { text: 'Serbian', code: 'sr' },
  { text: 'Serbo-Croatian', code: 'sh' },
  { text: 'Sesotho', code: 'st' },
  { text: 'Setswana', code: 'tn' },
  { text: 'Shona', code: 'sn' },
  { text: 'Sichuan Yi', code: 'ii' },
  { text: 'Sindhi', code: 'sd' },
  { text: 'Sinhalese', code: 'si' },
  { text: 'Siswati', code: 'ss' },
  { text: 'Slovak', code: 'sk' },
  { text: 'Slovenian', code: 'sl' },
  { text: 'Somali', code: 'so' },
  { text: 'Southern Ndebele', code: 'nr' },
  { text: 'Spanish', code: 'es' },
  { text: 'Sundanese', code: 'su' },
  { text: 'Swahili (Kiswahili)', code: 'sw' },
  { text: 'Swati', code: 'ss' },
  { text: 'Swedish', code: 'sv' },
  { text: 'Tagalog', code: 'tl' },
  { text: 'Tahitian', code: 'ty' },
  { text: 'Tajik', code: 'tg' },
  { text: 'Tamil', code: 'ta' },
  { text: 'Tatar', code: 'tt' },
  { text: 'Telugu', code: 'te' },
  { text: 'Thai', code: 'th' },
  { text: 'Tibetan', code: 'bo' },
  { text: 'Tigrinya', code: 'ti' },
  { text: 'Tonga', code: 'to' },
  { text: 'Tsonga', code: 'ts' },
  { text: 'Turkish', code: 'tr' },
  { text: 'Turkmen', code: 'tk' },
  { text: 'Twi', code: 'tw' },
  { text: 'Uyghur', code: 'ug' },
  { text: 'Ukrainian', code: 'uk' },
  { text: 'Urdu', code: 'ur' },
  { text: 'Uzbek', code: 'uz' },
  { text: 'Venda', code: 've' },
  { text: 'Vietnamese', code: 'vi' },
  { text: 'Volapük', code: 'vo' },
  { text: 'Wallon', code: 'wa' },
  { text: 'Welsh', code: 'cy' },
  { text: 'Wolof', code: 'wo' },
  { text: 'Western Frisian', code: 'fy' },
  { text: 'Xhosa', code: 'xh' },
  { text: 'Yiddish', code: 'yi, ji' },
  { text: 'Yoruba', code: 'yo' },
  { text: 'Zhuang, Chuang', code: 'za' },
  { text: 'Zlu', code: 'zu' },
];

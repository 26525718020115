import { Card, Group, Text } from '@mantine/core';

type Prop = {
  text: string;
  title: string;
  description: string;
};
const TextCard = (prop: Prop) => {
  return (
    <>
      <Card shadow="sm" p="lg" radius="md" withBorder mt={30}>
        <div dangerouslySetInnerHTML={{ __html: prop.text }} />
        <Group position="apart" mt="md" mb="xs"></Group>
        <Text weight={800}>{prop.title}</Text>
        <Text size="sm" color="dark">
          {prop.description}
        </Text>
      </Card>
    </>
  );
};
export default TextCard;

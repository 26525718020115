import { Text, Divider, Center } from '@mantine/core';
import LabelAndValue from './LabelAndValue';

export interface ProfileDetailData {
  name: string;
  dateOfBirth: string;
  profession: string;
  isicNumber: string;
  school: string;
  languages: string[];
  gender: string;
}

const ProfileDetail = (props: ProfileDetailData) => {
  return (
    <>
      <Center>
        <Text size="xl" color={'white'} weight={500}>
          {props.name}
        </Text>
      </Center>

      {props.dateOfBirth != '-' && (
        <>
          <LabelAndValue label="Date of birth" values={[props.dateOfBirth]} />
          <Divider mb={10} />
        </>
      )}

      {props.gender != '-' && (
        <>
          <LabelAndValue label="Gender" values={[props.gender]} />
          <Divider mb={10} />
        </>
      )}

      {props.school != '-' && (
        <>
          <LabelAndValue label="School" values={[props.school]} />
          <Divider mb={10} />
        </>
      )}

      {props.profession != '-' && (
        <>
          <LabelAndValue label="Profession" values={[props.profession]} />
          <Divider mb={10} />
        </>
      )}

      {props.languages.at(0) != '-' && (
        <>
          <LabelAndValue label="Languages" values={props.languages} />
          <Divider mb={10} />
        </>
      )}

      {props.isicNumber != '-' && <LabelAndValue label="ISIC" values={[props.isicNumber]} />}
    </>
  );
};
export default ProfileDetail;

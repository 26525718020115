import { Modal, Stack, Image, Text, Divider, Group, Alert } from '@mantine/core';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { ChallengesApiApi } from '../../@api/DigiVet';
import hurray from '../../assets/Challenge/hurray.png';
import { ApiWrapper } from '../../common/ApiWrapper';
import DigiVetButton from '../DigiVetButton';

type Props = {
  setIsSubmitted: (active: boolean) => void;
  files: File[];
  challengeId: string;
};
function ChallengeSubmitModal({ setIsSubmitted: setIsSubmitted, challengeId, files }: Props) {
  const [opened, setOpened] = useState(false);
  const auth = useAuth();
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false), setIsSubmitted(true);
        }}
      >
        <Stack align={'center'}>
          <Image width={30} src={hurray}></Image>
          <Text>Successfully submitted your project!</Text>
          <Divider my="sm" variant="dashed" />
          <Text> Hurray!</Text>
        </Stack>
      </Modal>
      <Group position="center">
        <DigiVetButton
          content="Submit"
          onClickHandler={() => {
            const challengesApi = new ApiWrapper<ChallengesApiApi>(auth, ChallengesApiApi);
            challengesApi.load(
              async () => {
                await challengesApi.getApiInstance().apiChallengesSubmitPost(challengeId, files);
              },
              () => {
                setOpened(false);
                <Alert title="Oops!" color="red" radius="xl">
                  Something terrible happened! You made a mistake and there is no going back.
                </Alert>;
              },
              () => {
                setOpened(false);
              },
            );
            setOpened(true);
          }}
        ></DigiVetButton>
      </Group>
    </>
  );
}

export default ChallengeSubmitModal;

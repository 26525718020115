import { Navigate, Route, Routes } from 'react-router-dom';
import MyProfile from '../pages/Profile/MyProfile';
import Login from '../pages/Profile/Login';
import Layout from './Layout';
import React from 'react';
import NotFound from '../pages/NotFound';
import MyMobility from '../pages/Mobility/MyMobility';
import ErasmusPlus from '../pages/Erasmus/ErasmusPlus';
import Registration from '../pages/Profile/Registration';
import { useAuth } from 'react-oidc-context';
import RegistrationNotFound from '../pages/Profile/RegistrationNotFound';
import Courses from '../pages/E-Learning/Courses';
import CourseView from '../pages/E-Learning/CourseView';
import CourseDetail from '../pages/E-Learning/CourseDetail';
import CoursesProvider from './E-Learning/CoursesProvider';
import SectionQuiz from '../pages/E-Learning/SectionQuiz';
import ChallengeDetail from '../pages/Challenge/ChallengeDetail';
import Challenges from '../pages/Challenge/Challenges';

const App: React.FC = () => {
  const auth = useAuth();

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <></>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  return (
    <Routes>
      {!auth.isAuthenticated && (
        <>
          <Route path="/registration/:userId/:code" element={<Registration />} />
          <Route path="/registration/contactInfo" element={<RegistrationNotFound />} />
          <Route path="*" element={<Login />} />
        </>
      )}
      {auth.isAuthenticated && (
        <>
          <Route path="/">
            <Route path="/login" element={<Login></Login>} />
            <Route path="/login-callback" element={<Navigate to="/" replace />} />
            <Route path="/logout-callback" element={<Navigate to="/" replace />} />
            <Route element={<Layout />}>
              <Route path="/" element={<MyProfile />} />

              <Route path="/mobility" element={<MyMobility />} />

              <Route path="/erasmus" element={<ErasmusPlus />} />

              <Route path="/challenges" element={<Challenges />} />
              <Route path="/challenges/:challengeId" element={<ChallengeDetail />} />

              <Route path="/courses" element={<Courses />} />
              <Route path="/courses/:courseId" element={<CoursesProvider />}>
                <Route path="" element={<CourseView />} />
                <Route path=":sectionId/:assetId" element={<CourseDetail />} />
                <Route path="quiz/:sectionId" element={<SectionQuiz />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </>
      )}
    </Routes>
  );
};

export default App;

import { Button, Collapse, createStyles } from '@mantine/core';
import { useState } from 'react';
import { CourseAssetDto } from '../../@api/DigiVet';
import { ChevronRight } from 'tabler-icons-react';
import SubCourse from './SubCourse';

type Props = {
  assetsTitle: string;
  courseAsset: CourseAssetDto[];
  sectionId: number;
  quizUrl: string;
};

const CourseAsset = (prop: Props) => {
  const useStyles = createStyles(() => ({
    courseButton: {
      backgroundColor: 'white',
      borderRadius: 25,
      width: 350,
    },
  }));

  const [opened, setOpened] = useState(false);

  const { classes } = useStyles();

  return (
    <>
      <Button
        fullWidth
        styles={{
          inner: {
            justifyContent: 'start',
            color: 'black',
          },
        }}
        className={classes.courseButton}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          padding: '0',
          border: 0,
          backgroundColor: 'rgba(255,255,255, 0.3)',
          div: {
            background: 'inherit',
            height: '100%',
            width: '120%',
            marginRight: 10,
            'span:nth-of-type(1n)': {
              margin: '0 6px 0 8px',
              svg: {
                transition: 'transform 0.1s ease-in',
                transform: opened ? 'rotate(90deg)' : 'rotate(0)',
              },
            },
            'span:nth-of-type(2n)': {
              width: '100%',
              background: 'white',
              paddingLeft: '10px',
              borderRadius: 25,
            },
          },
        }}
        leftIcon={<ChevronRight />}
        onClick={() => setOpened(opened => !opened)}
      >
        {prop.assetsTitle}
      </Button>
      <Collapse in={opened}>
        {prop.courseAsset.map((innerProp: CourseAssetDto, index: number) => (
          <SubCourse
            isQuiz={false}
            title={innerProp.title}
            path={innerProp.path}
            key={index}
            assetId={index}
            url={innerProp.url}
            sectionId={prop.sectionId}
            description={innerProp.description}
          />
        ))}
        {prop.quizUrl && (
          <SubCourse
            isQuiz={true}
            title={'Quiz'}
            path={prop.quizUrl}
            assetId={0}
            url={prop.quizUrl}
            sectionId={prop.sectionId}
          />
        )}
      </Collapse>
    </>
  );
};
export default CourseAsset;

import { Text, Center, Grid, Loader, Image } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { CourseListDto, TrainingApiApi } from '../../@api/DigiVet';
import { ApiWrapper } from '../../common/ApiWrapper';
import Course from '../../components/E-Learning/Course';
import DigiVetLogo from '../../assets/E-Learning/digivet.png';

const Courses = () => {
  const [loadState, setLoadState] = useState('loading');
  const auth = useAuth();
  const [courseData, setCourseData] = useState<CourseListDto[]>([]);
  useEffect(() => {
    const loadProfile = async () => {
      const trainingApi = new ApiWrapper<TrainingApiApi>(auth, TrainingApiApi);
      await trainingApi
        .load(async () => {
          const response = await trainingApi.getApiInstance().apiTrainingApiAllGet();
          setCourseData(response.data ?? []);
        })
        .then(() => setLoadState('loaded'));
    };
    loadProfile();
  }, []);

  if (loadState === 'loading') {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <Grid mb={20}>
        <Grid.Col span={5}>
          <Text
            sx={{
              fontSize: '35px',
            }}
            color={'white'}
          >
            Materials
          </Text>
        </Grid.Col>
        <Grid.Col span={5} offset={2}>
          <Image width={'75%'} src={DigiVetLogo} />
        </Grid.Col>
      </Grid>
      <Grid>
        {courseData.map((prop: CourseListDto, index: number) => (
          <Grid.Col key={index} md={6}>
            <Course {...prop} />
          </Grid.Col>
        ))}
      </Grid>
    </>
  );
};
export default Courses;

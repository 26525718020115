/* tslint:disable */
/* eslint-disable */
/**
 * DigiVet.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ChallengeApplyDto
 */
export interface ChallengeApplyDto {
  /**
   *
   * @type {string}
   * @memberof ChallengeApplyDto
   */
  teammates?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeApplyDto
   */
  challengeId?: string | null;
}
/**
 *
 * @export
 * @interface ChallengeDetailDto
 */
export interface ChallengeDetailDto {
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailDto
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailDto
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailDto
   */
  hostingCompanyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailDto
   */
  shortDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailDto
   */
  longDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailDto
   */
  deadline?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailDto
   */
  possibleReward?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailDto
   */
  source?: string | null;
  /**
   *
   * @type {ChallengeStatus}
   * @memberof ChallengeDetailDto
   */
  challengeStatus?: ChallengeStatus;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeDetailDto
   */
  isApplied?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeDetailDto
   */
  isTeamFormed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeDetailDto
   */
  isUnderEvaluation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeDetailDto
   */
  isWinnerChosen?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailDto
   */
  website?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeDetailDto
   */
  isCurrentUserWinner?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeDetailDto
   */
  isEvaluationFinished?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeDetailDto
   */
  isClosed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailDto
   */
  evaluationText?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ChallengeDetailDto
   */
  teammates: Array<string>;
}
/**
 *
 * @export
 * @interface ChallengeListDto
 */
export interface ChallengeListDto {
  /**
   *
   * @type {string}
   * @memberof ChallengeListDto
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeListDto
   */
  titleChallenge?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeListDto
   */
  hostingCompanyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeListDto
   */
  deadline?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChallengeListDto
   */
  possibleReward?: string | null;
  /**
   *
   * @type {ChallengeStatus}
   * @memberof ChallengeListDto
   */
  challengeStatus?: ChallengeStatus;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeListDto
   */
  isApplied?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeListDto
   */
  isTeamFormed?: boolean;
  /**
   *
   * @type {number}
   * @memberof ChallengeListDto
   */
  applicants?: number;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeListDto
   */
  isChosen?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeListDto
   */
  isUnderEvaluation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeListDto
   */
  isEvaluationFinished?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeListDto
   */
  isClosed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChallengeListDto
   */
  source?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ChallengeStatus = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type ChallengeStatus = typeof ChallengeStatus[keyof typeof ChallengeStatus];

/**
 *
 * @export
 * @interface ContactDto
 */
export interface ContactDto {
  /**
   *
   * @type {string}
   * @memberof ContactDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactDto
   */
  phoneNumber?: string | null;
}
/**
 *
 * @export
 * @interface ContentItem
 */
export interface ContentItem {
  /**
   *
   * @type {any}
   * @memberof ContentItem
   */
  content?: any | null;
  /**
   *
   * @type {ContentItem}
   * @memberof ContentItem
   */
  contentItem?: ContentItem;
  /**
   *
   * @type {number}
   * @memberof ContentItem
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ContentItem
   */
  contentItemId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContentItem
   */
  contentItemVersionId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContentItem
   */
  contentType?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ContentItem
   */
  published?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ContentItem
   */
  latest?: boolean;
  /**
   *
   * @type {string}
   * @memberof ContentItem
   */
  modifiedUtc?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContentItem
   */
  publishedUtc?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContentItem
   */
  createdUtc?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContentItem
   */
  owner?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContentItem
   */
  author?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContentItem
   */
  displayText?: string | null;
}
/**
 *
 * @export
 * @interface CourseAssetDto
 */
export interface CourseAssetDto {
  /**
   *
   * @type {string}
   * @memberof CourseAssetDto
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof CourseAssetDto
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof CourseAssetDto
   */
  path?: string | null;
  /**
   *
   * @type {string}
   * @memberof CourseAssetDto
   */
  url?: string | null;
  /**
   *
   * @type {string}
   * @memberof CourseAssetDto
   */
  text?: string | null;
}
/**
 *
 * @export
 * @interface CourseDetailDto
 */
export interface CourseDetailDto {
  /**
   *
   * @type {string}
   * @memberof CourseDetailDto
   */
  assetsTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof CourseDetailDto
   */
  quizUrl?: string | null;
  /**
   *
   * @type {Array<CourseAssetDto>}
   * @memberof CourseDetailDto
   */
  assets?: Array<CourseAssetDto> | null;
}
/**
 *
 * @export
 * @interface CourseListDto
 */
export interface CourseListDto {
  /**
   *
   * @type {string}
   * @memberof CourseListDto
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof CourseListDto
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof CourseListDto
   */
  tag?: string | null;
  /**
   *
   * @type {string}
   * @memberof CourseListDto
   */
  id?: string | null;
}
/**
 *
 * @export
 * @interface CreateApiViewModel
 */
export interface CreateApiViewModel {
  /**
   *
   * @type {string}
   * @memberof CreateApiViewModel
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApiViewModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateApiViewModel
   */
  databaseProvider?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApiViewModel
   */
  requestUrlPrefix?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApiViewModel
   */
  requestUrlHost?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApiViewModel
   */
  connectionString?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApiViewModel
   */
  tablePrefix?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApiViewModel
   */
  recipeName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApiViewModel
   */
  featureProfile?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateApiViewModel
   */
  isNewTenant?: boolean;
}
/**
 *
 * @export
 * @interface LanguageAndLevel
 */
export interface LanguageAndLevel {
  /**
   *
   * @type {string}
   * @memberof LanguageAndLevel
   */
  language: string;
  /**
   *
   * @type {string}
   * @memberof LanguageAndLevel
   */
  level: string;
}
/**
 *
 * @export
 * @interface LanguageDto
 */
export interface LanguageDto {
  /**
   *
   * @type {string}
   * @memberof LanguageDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof LanguageDto
   */
  level?: string | null;
}
/**
 *
 * @export
 * @interface LanguageSelectList
 */
export interface LanguageSelectList {
  /**
   *
   * @type {string}
   * @memberof LanguageSelectList
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof LanguageSelectList
   */
  level: string;
  /**
   *
   * @type {boolean}
   * @memberof LanguageSelectList
   */
  disabled: boolean;
}
/**
 *
 * @export
 * @interface MyMobilityDto
 */
export interface MyMobilityDto {
  /**
   *
   * @type {string}
   * @memberof MyMobilityDto
   */
  name?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof MyMobilityDto
   */
  accommodation?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof MyMobilityDto
   */
  hostingCompany?: Array<string> | null;
  /**
   *
   * @type {Array<ContactDto>}
   * @memberof MyMobilityDto
   */
  tutorsContact?: Array<ContactDto> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof MyMobilityDto
   */
  workingTime?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof MyMobilityDto
   */
  dressCode?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof MyMobilityDto
   */
  equipments?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MyProfileDto
 */
export interface MyProfileDto {
  /**
   *
   * @type {string}
   * @memberof MyProfileDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MyProfileDto
   */
  dateOfBirth?: string | null;
  /**
   *
   * @type {string}
   * @memberof MyProfileDto
   */
  gender?: string | null;
  /**
   *
   * @type {string}
   * @memberof MyProfileDto
   */
  school?: string | null;
  /**
   *
   * @type {string}
   * @memberof MyProfileDto
   */
  profession?: string | null;
  /**
   *
   * @type {Array<LanguageDto>}
   * @memberof MyProfileDto
   */
  languages?: Array<LanguageDto> | null;
  /**
   *
   * @type {string}
   * @memberof MyProfileDto
   */
  isicNumber?: string | null;
}
/**
 *
 * @export
 * @interface SampleDto
 */
export interface SampleDto {
  /**
   *
   * @type {string}
   * @memberof SampleDto
   */
  id?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SampleDto
   */
  messages?: Array<string> | null;
}
/**
 *
 * @export
 * @interface SetupApiViewModel
 */
export interface SetupApiViewModel {
  /**
   *
   * @type {string}
   * @memberof SetupApiViewModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SetupApiViewModel
   */
  siteName: string;
  /**
   *
   * @type {string}
   * @memberof SetupApiViewModel
   */
  databaseProvider?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetupApiViewModel
   */
  connectionString?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetupApiViewModel
   */
  tablePrefix?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetupApiViewModel
   */
  userName: string;
  /**
   *
   * @type {string}
   * @memberof SetupApiViewModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof SetupApiViewModel
   */
  password?: string | null;
  /**
   *
   * @type {string}
   * @memberof SetupApiViewModel
   */
  recipeName?: string | null;
  /**
   *
   * @type {any}
   * @memberof SetupApiViewModel
   */
  recipe?: any | null;
  /**
   *
   * @type {string}
   * @memberof SetupApiViewModel
   */
  siteTimeZone?: string | null;
}
/**
 *
 * @export
 * @interface UserExistedDto
 */
export interface UserExistedDto {
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  phoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  sex?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  dateOfBirth?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  diet?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  idNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  isicNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  medicines?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  school?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  allergies?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExistedDto
   */
  profession?: string | null;
  /**
   *
   * @type {Array<LanguageAndLevel>}
   * @memberof UserExistedDto
   */
  languages?: Array<LanguageAndLevel> | null;
  /**
   *
   * @type {Array<LanguageSelectList>}
   * @memberof UserExistedDto
   */
  languageSelectList: Array<LanguageSelectList>;
}

/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} contentItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContentContentItemIdDelete: async (
      contentItemId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contentItemId' is not null or undefined
      assertParamExists('apiContentContentItemIdDelete', 'contentItemId', contentItemId);
      const localVarPath = `/api/content/{contentItemId}`.replace(
        `{${'contentItemId'}}`,
        encodeURIComponent(String(contentItemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} contentItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContentContentItemIdGet: async (
      contentItemId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contentItemId' is not null or undefined
      assertParamExists('apiContentContentItemIdGet', 'contentItemId', contentItemId);
      const localVarPath = `/api/content/{contentItemId}`.replace(
        `{${'contentItemId'}}`,
        encodeURIComponent(String(contentItemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} [draft]
     * @param {ContentItem} [contentItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContentPost: async (
      draft?: boolean,
      contentItem?: ContentItem,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/content`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      if (draft !== undefined) {
        localVarQueryParameter['draft'] = draft;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(contentItem, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} name
     * @param {string} [parameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiQueriesNameGet: async (
      name: string,
      parameters?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists('apiQueriesNameGet', 'name', name);
      const localVarPath = `/api/queries/{name}`.replace(`{${'name'}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      if (parameters !== undefined) {
        localVarQueryParameter['parameters'] = parameters;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} name
     * @param {string} [parameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiQueriesNamePost: async (
      name: string,
      parameters?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists('apiQueriesNamePost', 'name', name);
      const localVarPath = `/api/queries/{name}`.replace(`{${'name'}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      if (parameters !== undefined) {
        localVarQueryParameter['parameters'] = parameters;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateApiViewModel} [createApiViewModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTenantsCreatePost: async (
      createApiViewModel?: CreateApiViewModel,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/tenants/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createApiViewModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SetupApiViewModel} [setupApiViewModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTenantsSetupPost: async (
      setupApiViewModel?: SetupApiViewModel,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/tenants/setup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(setupApiViewModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} contentItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContentContentItemIdDelete(
      contentItemId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentContentItemIdDelete(contentItemId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} contentItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContentContentItemIdGet(
      contentItemId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentContentItemIdGet(contentItemId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {boolean} [draft]
     * @param {ContentItem} [contentItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContentPost(
      draft?: boolean,
      contentItem?: ContentItem,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentPost(draft, contentItem, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} name
     * @param {string} [parameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiQueriesNameGet(
      name: string,
      parameters?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiQueriesNameGet(name, parameters, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} name
     * @param {string} [parameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiQueriesNamePost(
      name: string,
      parameters?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiQueriesNamePost(name, parameters, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateApiViewModel} [createApiViewModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTenantsCreatePost(
      createApiViewModel?: CreateApiViewModel,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantsCreatePost(createApiViewModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SetupApiViewModel} [setupApiViewModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTenantsSetupPost(
      setupApiViewModel?: SetupApiViewModel,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantsSetupPost(setupApiViewModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} contentItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContentContentItemIdDelete(contentItemId: string, options?: any): AxiosPromise<void> {
      return localVarFp.apiContentContentItemIdDelete(contentItemId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} contentItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContentContentItemIdGet(contentItemId: string, options?: any): AxiosPromise<void> {
      return localVarFp.apiContentContentItemIdGet(contentItemId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} [draft]
     * @param {ContentItem} [contentItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContentPost(draft?: boolean, contentItem?: ContentItem, options?: any): AxiosPromise<void> {
      return localVarFp.apiContentPost(draft, contentItem, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} name
     * @param {string} [parameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiQueriesNameGet(name: string, parameters?: string, options?: any): AxiosPromise<void> {
      return localVarFp.apiQueriesNameGet(name, parameters, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} name
     * @param {string} [parameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiQueriesNamePost(name: string, parameters?: string, options?: any): AxiosPromise<void> {
      return localVarFp.apiQueriesNamePost(name, parameters, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CreateApiViewModel} [createApiViewModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTenantsCreatePost(createApiViewModel?: CreateApiViewModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiTenantsCreatePost(createApiViewModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {SetupApiViewModel} [setupApiViewModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTenantsSetupPost(setupApiViewModel?: SetupApiViewModel, options?: any): AxiosPromise<void> {
      return localVarFp.apiTenantsSetupPost(setupApiViewModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
  /**
   *
   * @param {string} contentItemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiContentContentItemIdDelete(contentItemId: string, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiContentContentItemIdDelete(contentItemId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} contentItemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiContentContentItemIdGet(contentItemId: string, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiContentContentItemIdGet(contentItemId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {boolean} [draft]
   * @param {ContentItem} [contentItem]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiContentPost(draft?: boolean, contentItem?: ContentItem, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiContentPost(draft, contentItem, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} name
   * @param {string} [parameters]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiQueriesNameGet(name: string, parameters?: string, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiQueriesNameGet(name, parameters, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} name
   * @param {string} [parameters]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiQueriesNamePost(name: string, parameters?: string, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiQueriesNamePost(name, parameters, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateApiViewModel} [createApiViewModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiTenantsCreatePost(createApiViewModel?: CreateApiViewModel, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiTenantsCreatePost(createApiViewModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SetupApiViewModel} [setupApiViewModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public apiTenantsSetupPost(setupApiViewModel?: SetupApiViewModel, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .apiTenantsSetupPost(setupApiViewModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ChallengesApiApi - axios parameter creator
 * @export
 */
export const ChallengesApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiChallengesAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Challenges/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ChallengeApplyDto} [challengeApplyDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiChallengesApplyPost: async (
      challengeApplyDto?: ChallengeApplyDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Challenges/Apply`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(challengeApplyDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [challengeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiChallengesChallengeDetailGet: async (
      challengeId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Challenges/ChallengeDetail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      if (challengeId !== undefined) {
        localVarQueryParameter['challengeId'] = challengeId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [challengeId]
     * @param {Array<any>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiChallengesSubmitPost: async (
      challengeId?: string,
      files?: Array<any>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Challenges/Submit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      if (challengeId !== undefined) {
        localVarFormParams.append('ChallengeId', challengeId as any);
      }
      if (files) {
        files.forEach(element => {
          localVarFormParams.append('Files', element as any);
        });
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChallengesApiApi - functional programming interface
 * @export
 */
export const ChallengesApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ChallengesApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiChallengesAllGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChallengeListDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiChallengesAllGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ChallengeApplyDto} [challengeApplyDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiChallengesApplyPost(
      challengeApplyDto?: ChallengeApplyDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiChallengesApplyPost(challengeApplyDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [challengeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiChallengesChallengeDetailGet(
      challengeId?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeDetailDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiChallengesChallengeDetailGet(challengeId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [challengeId]
     * @param {Array<any>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiChallengesSubmitPost(
      challengeId?: string,
      files?: Array<any>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiChallengesSubmitPost(challengeId, files, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ChallengesApiApi - factory interface
 * @export
 */
export const ChallengesApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ChallengesApiApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiChallengesAllGet(options?: any): AxiosPromise<Array<ChallengeListDto>> {
      return localVarFp.apiChallengesAllGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ChallengeApplyDto} [challengeApplyDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiChallengesApplyPost(challengeApplyDto?: ChallengeApplyDto, options?: any): AxiosPromise<void> {
      return localVarFp.apiChallengesApplyPost(challengeApplyDto, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [challengeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiChallengesChallengeDetailGet(challengeId?: string, options?: any): AxiosPromise<ChallengeDetailDto> {
      return localVarFp.apiChallengesChallengeDetailGet(challengeId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [challengeId]
     * @param {Array<any>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiChallengesSubmitPost(challengeId?: string, files?: Array<any>, options?: any): AxiosPromise<void> {
      return localVarFp.apiChallengesSubmitPost(challengeId, files, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ChallengesApiApi - object-oriented interface
 * @export
 * @class ChallengesApiApi
 * @extends {BaseAPI}
 */
export class ChallengesApiApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengesApiApi
   */
  public apiChallengesAllGet(options?: AxiosRequestConfig) {
    return ChallengesApiApiFp(this.configuration)
      .apiChallengesAllGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ChallengeApplyDto} [challengeApplyDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengesApiApi
   */
  public apiChallengesApplyPost(challengeApplyDto?: ChallengeApplyDto, options?: AxiosRequestConfig) {
    return ChallengesApiApiFp(this.configuration)
      .apiChallengesApplyPost(challengeApplyDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [challengeId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengesApiApi
   */
  public apiChallengesChallengeDetailGet(challengeId?: string, options?: AxiosRequestConfig) {
    return ChallengesApiApiFp(this.configuration)
      .apiChallengesChallengeDetailGet(challengeId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [challengeId]
   * @param {Array<any>} [files]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengesApiApi
   */
  public apiChallengesSubmitPost(challengeId?: string, files?: Array<any>, options?: AxiosRequestConfig) {
    return ChallengesApiApiFp(this.configuration)
      .apiChallengesSubmitPost(challengeId, files, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * MyMobilityApiApi - axios parameter creator
 * @export
 */
export const MyMobilityApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMyMobilityGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/my-mobility`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MyMobilityApiApi - functional programming interface
 * @export
 */
export const MyMobilityApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MyMobilityApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMyMobilityGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyMobilityDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMyMobilityGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MyMobilityApiApi - factory interface
 * @export
 */
export const MyMobilityApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MyMobilityApiApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMyMobilityGet(options?: any): AxiosPromise<MyMobilityDto> {
      return localVarFp.apiMyMobilityGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * MyMobilityApiApi - object-oriented interface
 * @export
 * @class MyMobilityApiApi
 * @extends {BaseAPI}
 */
export class MyMobilityApiApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyMobilityApiApi
   */
  public apiMyMobilityGet(options?: AxiosRequestConfig) {
    return MyMobilityApiApiFp(this.configuration)
      .apiMyMobilityGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * MyProfileApiApi - axios parameter creator
 * @export
 */
export const MyProfileApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMyProfileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/my-profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMyProfileLogOutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/my-profile/LogOut`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MyProfileApiApi - functional programming interface
 * @export
 */
export const MyProfileApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MyProfileApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMyProfileGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyProfileDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMyProfileGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMyProfileLogOutPost(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMyProfileLogOutPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MyProfileApiApi - factory interface
 * @export
 */
export const MyProfileApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MyProfileApiApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMyProfileGet(options?: any): AxiosPromise<MyProfileDto> {
      return localVarFp.apiMyProfileGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMyProfileLogOutPost(options?: any): AxiosPromise<void> {
      return localVarFp.apiMyProfileLogOutPost(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * MyProfileApiApi - object-oriented interface
 * @export
 * @class MyProfileApiApi
 * @extends {BaseAPI}
 */
export class MyProfileApiApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyProfileApiApi
   */
  public apiMyProfileGet(options?: AxiosRequestConfig) {
    return MyProfileApiApiFp(this.configuration)
      .apiMyProfileGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyProfileApiApi
   */
  public apiMyProfileLogOutPost(options?: AxiosRequestConfig) {
    return MyProfileApiApiFp(this.configuration)
      .apiMyProfileLogOutPost(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SampleApiApi - axios parameter creator
 * @export
 */
export const SampleApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSampleGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/sample`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SampleApiApi - functional programming interface
 * @export
 */
export const SampleApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SampleApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSampleGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SampleDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSampleGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SampleApiApi - factory interface
 * @export
 */
export const SampleApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SampleApiApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSampleGet(options?: any): AxiosPromise<SampleDto> {
      return localVarFp.apiSampleGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SampleApiApi - object-oriented interface
 * @export
 * @class SampleApiApi
 * @extends {BaseAPI}
 */
export class SampleApiApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SampleApiApi
   */
  public apiSampleGet(options?: AxiosRequestConfig) {
    return SampleApiApiFp(this.configuration)
      .apiSampleGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * StudentRegistrationApiApi - axios parameter creator
 * @export
 */
export const StudentRegistrationApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [userId]
     * @param {string} [code]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStudentRegistrationGet: async (
      userId?: string,
      code?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/student-registration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {string} email
     * @param {string} password
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} phoneNumber
     * @param {string} dateOfBirth
     * @param {string} sex
     * @param {string} [contentItemId]
     * @param {string} [idNumber]
     * @param {string} [isicNumber]
     * @param {string} [allergies]
     * @param {string} [medicines]
     * @param {string} [diet]
     * @param {any} [cv]
     * @param {Array<LanguageAndLevel>} [languages]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStudentRegistrationPost: async (
      code: string,
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      phoneNumber: string,
      dateOfBirth: string,
      sex: string,
      contentItemId?: string,
      idNumber?: string,
      isicNumber?: string,
      allergies?: string,
      medicines?: string,
      diet?: string,
      cv?: any,
      languages?: Array<LanguageAndLevel>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('apiStudentRegistrationPost', 'code', code);
      // verify required parameter 'email' is not null or undefined
      assertParamExists('apiStudentRegistrationPost', 'email', email);
      // verify required parameter 'password' is not null or undefined
      assertParamExists('apiStudentRegistrationPost', 'password', password);
      // verify required parameter 'firstName' is not null or undefined
      assertParamExists('apiStudentRegistrationPost', 'firstName', firstName);
      // verify required parameter 'lastName' is not null or undefined
      assertParamExists('apiStudentRegistrationPost', 'lastName', lastName);
      // verify required parameter 'phoneNumber' is not null or undefined
      assertParamExists('apiStudentRegistrationPost', 'phoneNumber', phoneNumber);
      // verify required parameter 'dateOfBirth' is not null or undefined
      assertParamExists('apiStudentRegistrationPost', 'dateOfBirth', dateOfBirth);
      // verify required parameter 'sex' is not null or undefined
      assertParamExists('apiStudentRegistrationPost', 'sex', sex);
      const localVarPath = `/api/student-registration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      if (contentItemId !== undefined) {
        localVarFormParams.append('ContentItemId', contentItemId as any);
      }

      if (code !== undefined) {
        localVarFormParams.append('Code', code as any);
      }

      if (email !== undefined) {
        localVarFormParams.append('Email', email as any);
      }

      if (password !== undefined) {
        localVarFormParams.append('Password', password as any);
      }

      if (firstName !== undefined) {
        localVarFormParams.append('FirstName', firstName as any);
      }

      if (lastName !== undefined) {
        localVarFormParams.append('LastName', lastName as any);
      }

      if (phoneNumber !== undefined) {
        localVarFormParams.append('PhoneNumber', phoneNumber as any);
      }

      if (dateOfBirth !== undefined) {
        localVarFormParams.append('DateOfBirth', dateOfBirth as any);
      }

      if (sex !== undefined) {
        localVarFormParams.append('Sex', sex as any);
      }

      if (idNumber !== undefined) {
        localVarFormParams.append('IdNumber', idNumber as any);
      }

      if (isicNumber !== undefined) {
        localVarFormParams.append('IsicNumber', isicNumber as any);
      }

      if (allergies !== undefined) {
        localVarFormParams.append('Allergies', allergies as any);
      }

      if (medicines !== undefined) {
        localVarFormParams.append('Medicines', medicines as any);
      }

      if (diet !== undefined) {
        localVarFormParams.append('Diet', diet as any);
      }

      if (cv !== undefined) {
        localVarFormParams.append('Cv', cv as any);
      }
      if (languages) {
        // Multipart form, array of complex objects
        languages.forEach((element, i) => {
          Object.keys(element).forEach(prop =>
            localVarFormParams.append(`Languages[${i}].${prop}`, element[prop as keyof LanguageAndLevel] as any),
          );
        });
      }
      ``;

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StudentRegistrationApiApi - functional programming interface
 * @export
 */
export const StudentRegistrationApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StudentRegistrationApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [userId]
     * @param {string} [code]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiStudentRegistrationGet(
      userId?: string,
      code?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserExistedDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiStudentRegistrationGet(userId, code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} code
     * @param {string} email
     * @param {string} password
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} phoneNumber
     * @param {string} dateOfBirth
     * @param {string} sex
     * @param {string} [contentItemId]
     * @param {string} [idNumber]
     * @param {string} [isicNumber]
     * @param {string} [allergies]
     * @param {string} [medicines]
     * @param {string} [diet]
     * @param {any} [cv]
     * @param {Array<LanguageAndLevel>} [languages]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiStudentRegistrationPost(
      code: string,
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      phoneNumber: string,
      dateOfBirth: string,
      sex: string,
      contentItemId?: string,
      idNumber?: string,
      isicNumber?: string,
      allergies?: string,
      medicines?: string,
      diet?: string,
      cv?: any,
      languages?: Array<LanguageAndLevel>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiStudentRegistrationPost(
        code,
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        dateOfBirth,
        sex,
        contentItemId,
        idNumber,
        isicNumber,
        allergies,
        medicines,
        diet,
        cv,
        languages,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * StudentRegistrationApiApi - factory interface
 * @export
 */
export const StudentRegistrationApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StudentRegistrationApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} [userId]
     * @param {string} [code]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStudentRegistrationGet(userId?: string, code?: string, options?: any): AxiosPromise<UserExistedDto> {
      return localVarFp.apiStudentRegistrationGet(userId, code, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {string} email
     * @param {string} password
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} phoneNumber
     * @param {string} dateOfBirth
     * @param {string} sex
     * @param {string} [contentItemId]
     * @param {string} [idNumber]
     * @param {string} [isicNumber]
     * @param {string} [allergies]
     * @param {string} [medicines]
     * @param {string} [diet]
     * @param {any} [cv]
     * @param {Array<LanguageAndLevel>} [languages]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStudentRegistrationPost(
      code: string,
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      phoneNumber: string,
      dateOfBirth: string,
      sex: string,
      contentItemId?: string,
      idNumber?: string,
      isicNumber?: string,
      allergies?: string,
      medicines?: string,
      diet?: string,
      cv?: any,
      languages?: Array<LanguageAndLevel>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiStudentRegistrationPost(
          code,
          email,
          password,
          firstName,
          lastName,
          phoneNumber,
          dateOfBirth,
          sex,
          contentItemId,
          idNumber,
          isicNumber,
          allergies,
          medicines,
          diet,
          cv,
          languages,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * StudentRegistrationApiApi - object-oriented interface
 * @export
 * @class StudentRegistrationApiApi
 * @extends {BaseAPI}
 */
export class StudentRegistrationApiApi extends BaseAPI {
  /**
   *
   * @param {string} [userId]
   * @param {string} [code]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentRegistrationApiApi
   */
  public apiStudentRegistrationGet(userId?: string, code?: string, options?: AxiosRequestConfig) {
    return StudentRegistrationApiApiFp(this.configuration)
      .apiStudentRegistrationGet(userId, code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} code
   * @param {string} email
   * @param {string} password
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} phoneNumber
   * @param {string} dateOfBirth
   * @param {string} sex
   * @param {string} [contentItemId]
   * @param {string} [idNumber]
   * @param {string} [isicNumber]
   * @param {string} [allergies]
   * @param {string} [medicines]
   * @param {string} [diet]
   * @param {any} [cv]
   * @param {Array<LanguageAndLevel>} [languages]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentRegistrationApiApi
   */
  public apiStudentRegistrationPost(
    code: string,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    dateOfBirth: string,
    sex: string,
    contentItemId?: string,
    idNumber?: string,
    isicNumber?: string,
    allergies?: string,
    medicines?: string,
    diet?: string,
    cv?: any,
    languages?: Array<LanguageAndLevel>,
    options?: AxiosRequestConfig,
  ) {
    return StudentRegistrationApiApiFp(this.configuration)
      .apiStudentRegistrationPost(
        code,
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        dateOfBirth,
        sex,
        contentItemId,
        idNumber,
        isicNumber,
        allergies,
        medicines,
        diet,
        cv,
        languages,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TrainingApiApi - axios parameter creator
 * @export
 */
export const TrainingApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTrainingApiAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Training-Api/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [courseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTrainingApiCourseDetailGet: async (
      courseId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Training-Api/CourseDetail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'bearer', [], configuration);

      if (courseId !== undefined) {
        localVarQueryParameter['courseId'] = courseId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TrainingApiApi - functional programming interface
 * @export
 */
export const TrainingApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TrainingApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTrainingApiAllGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseListDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrainingApiAllGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [courseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTrainingApiCourseDetailGet(
      courseId?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseDetailDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrainingApiCourseDetailGet(courseId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TrainingApiApi - factory interface
 * @export
 */
export const TrainingApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TrainingApiApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTrainingApiAllGet(options?: any): AxiosPromise<Array<CourseListDto>> {
      return localVarFp.apiTrainingApiAllGet(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [courseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTrainingApiCourseDetailGet(courseId?: string, options?: any): AxiosPromise<Array<CourseDetailDto>> {
      return localVarFp.apiTrainingApiCourseDetailGet(courseId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * TrainingApiApi - object-oriented interface
 * @export
 * @class TrainingApiApi
 * @extends {BaseAPI}
 */
export class TrainingApiApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiApi
   */
  public apiTrainingApiAllGet(options?: AxiosRequestConfig) {
    return TrainingApiApiFp(this.configuration)
      .apiTrainingApiAllGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [courseId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiApi
   */
  public apiTrainingApiCourseDetailGet(courseId?: string, options?: AxiosRequestConfig) {
    return TrainingApiApiFp(this.configuration)
      .apiTrainingApiCourseDetailGet(courseId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

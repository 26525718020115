import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import './PdfPage.css';
import { useState } from 'react';

type Prop = {
  path: string;
};

export default function PdfCard(prop: Prop) {
  const [numPages, setNumPages] = useState<number>(0);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }
  return (
    <div style={{ overflow: 'hidden' }}>
      <Document file={prop.path} options={{ workerSrc: '/pdf.worker.js' }} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  );
}

import { Box, Group, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { CourseListDto } from '../../@api/DigiVet';

const Course = (prop: CourseListDto) => {
  return (
    <>
      <Box
        sx={theme => ({
          background: 'white',
          textAlign: 'center',
          padding: theme.spacing.xl,
          borderRadius: theme.radius.xl,
        })}
      >
        <Group position="apart" mt="md" mb="xs">
          <Link to={`/courses/${prop.id}`}>
            <Text size="xl" style={{ color: 'black', textDecoration: 'none' }}>
              {prop.title}
            </Text>
          </Link>
          <Text
            size="xl"
            style={{ color: 'black', textDecoration: 'none' }}
            variant="link"
            component="a"
            href={`courses/${prop.id}`}
          ></Text>
          <Text color="black">{prop.tag}</Text>
        </Group>
        <Text color={'black'} size="sm">
          {prop.description}
        </Text>
      </Box>
    </>
  );
};
export default Course;

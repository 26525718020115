import { Center, Avatar, Grid, Text, Image } from '@mantine/core';
import profile from '../../assets/profilepicture.png';
import DigiVetLogo from '../../assets/E-Learning/digivet.png';

const ProfileHeader = () => {
  return (
    <>
      <Grid mb={20}>
        <Grid.Col span={5}>
          <Text
            sx={{
              fontSize: '30px',
            }}
            color={'white'}
            mt={'10px'}
          >
            My Profile
          </Text>
        </Grid.Col>
        <Grid.Col span={5} offset={2}>
          <Image width={'75%'} src={DigiVetLogo} />
        </Grid.Col>
      </Grid>
      <Center>
        <Avatar src={profile} radius={100} size={127} alt="PP" />
      </Center>
    </>
  );
};
export default ProfileHeader;

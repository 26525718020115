import { Center, Group, Loader, Title, Text, Image, Grid, Divider } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { ChallengeListDto, ChallengesApiApi } from '../../@api/DigiVet';
import { ApiWrapper } from '../../common/ApiWrapper';
import Challenge from '../../components/Challenges/Challenge';
import DigiVetLogo from '../../assets/E-Learning/digivet.png';

const Challenges = () => {
  const [challengeList, setChallengeList] = useState<ChallengeListDto[]>([]);
  const auth = useAuth();
  const [loadState, setLoadState] = useState('loading');

  useEffect(() => {
    const loadExistedData = async () => {
      const challengesApi = new ApiWrapper<ChallengesApiApi>(auth, ChallengesApiApi);
      challengesApi
        .load(async () => {
          var response = await challengesApi.getApiInstance().apiChallengesAllGet();
          setChallengeList(response.data ?? []);
        })
        .then(() => setLoadState('loaded'));
    };
    loadExistedData();
  }, []);

  if (loadState === 'loading') {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  const RenderChallenges = ({ isClosed }: { isClosed: boolean }) => {
    const filteredList = useMemo(() => challengeList.filter(t => t.isClosed === isClosed), [challengeList, isClosed]);
    return (
      <>
        {filteredList.length > 0 ? (
          <Group>
            {filteredList.map((prop: ChallengeListDto, index: number) => (
              <Challenge key={index} {...prop} />
            ))}
          </Group>
        ) : (
          <>
            <Title color={'white'} order={5}>
              No Challenges Available
            </Title>
            <Divider mb={30} />
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Grid>
        <Grid.Col span={5}></Grid.Col>
        <Grid.Col span={5} offset={2}>
          <Image width={'75%'} src={DigiVetLogo} />
        </Grid.Col>
      </Grid>
      {challengeList.length > 0 ? (
        <>
          <Text color={'white'} size={'xl'} mb={10}>
            Running challenges
          </Text>
          <RenderChallenges isClosed={false} />
          <Text color={'white'} size={'xl'} mb={10}>
            Closed challenges
          </Text>
          <RenderChallenges isClosed={true} />
        </>
      ) : (
        <Title color={'white'} order={5}>
          No Challenges Available
        </Title>
      )}
    </>
  );
};

export default Challenges;

// export const DeadlineChecker = (deadline: string): boolean => {
//   let temp = new Date(deadline);
//   let now = new Date();
//   return temp > now;
// };

import { useForm } from '@mantine/form';
import { DatePicker } from '@mantine/dates';
import { useParams } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import remove from '../../assets/Challenge/remove.svg';
import DigiVetLogo from '../../assets/E-Learning/digivet.png';

import {
  PasswordInput,
  Group,
  Text,
  Box,
  TextInput,
  createStyles,
  Radio,
  Select,
  Switch,
  Center,
  ActionIcon,
  Image,
  Loader,
  Grid,
} from '@mantine/core';

import DigiVetButton from '../../components/DigiVetButton';
import { useState } from 'react';
import cancel from '../../assets/Vector.svg';
import { ApiWrapper } from '../../common/ApiWrapper';
import { LanguageAndLevel, LanguageSelectList, StudentRegistrationApiApi } from '../../@api/DigiVet';
import FormDropzone from '../../components/FormDragzone';
import { GetCodeByLanguageText, GetLanguageTextByCode } from '../../Helpers/LanguageHelper';

const useStyles = createStyles(() => ({
  label: {
    marginTop: '10px',
    label: { color: 'white' },
  },
  text: {
    marginTop: '10px',
    label: { color: 'white' },
  },
  radioGroup: {
    label: { color: 'white' },
    marginTop: '10px',
    color: 'white !important',
  },
  radioGroupLabel: {
    color: 'white',
  },
  switch: {
    color: 'white',
    background: 'white',
    backgroundColor: 'white',
  },

  languageText: {
    width: '40%',
  },
  languageSelect: {
    width: '40%',
  },
}));

type TFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  birthDate: Date;
  sex: string;
  school: string;
  profession: string;
  idNumber: string;
  isicNumber: string;
  allergies: string;
  medicines: string;
  diet: string;
  cv: File[];
  languages: LanguageAndLevel[];
};

function Registration() {
  const noValue = '';
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const { classes } = useStyles();
  const [languageSelectList, setLanguageSelectList] = useState<LanguageSelectList[]>([]);

  useEffect(() => {
    const loadExistedData = async () => {
      const studentRegistrationApi = new ApiWrapper<StudentRegistrationApiApi>(auth, StudentRegistrationApiApi);
      await studentRegistrationApi.load(
        async () => {
          const data = await studentRegistrationApi
            .getApiInstance()
            .apiStudentRegistrationGet(params.userId, params.code);
          setLanguageSelectList(data.data.languageSelectList ?? []);
          setIsLoading(false);
          data.data.languages?.map(x => (x.language = GetLanguageTextByCode(x.language)));
          form.setValues({
            firstName: data.data.firstName ?? noValue,
            lastName: data.data.lastName ?? noValue,
            phoneNumber: data.data.phoneNumber ?? noValue,
            email: data.data.email ?? noValue,
            sex: data.data.sex ?? noValue,
            birthDate: new Date(data.data.dateOfBirth ?? new Date(1999, 1, 1)),
            allergies: data.data.allergies ?? noValue,
            diet: data.data.diet ?? noValue,
            idNumber: data.data.idNumber ?? noValue,
            isicNumber: data.data.isicNumber ?? noValue,
            medicines: data.data.medicines ?? noValue,
            school: data.data.school ?? noValue,
            profession: data.data.profession ?? noValue,
            languages: data.data.languages ?? [],
            cv: [],
            password: noValue,
            confirmPassword: noValue,
          });
        },
        () => {
          navigate('/registration/contactInfo');
        },
        () => {
          navigate('/registration/contactInfo');
        },
      );
    };
    loadExistedData();
  }, []);

  const form = useForm<TFormValues>({
    initialValues: {
      password: '',
      confirmPassword: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '+',
      birthDate: new Date(),
      sex: '',
      allergies: '',
      idNumber: '',
      medicines: '',
      isicNumber: '',
      diet: '',
      profession: '',
      school: '',
      cv: [],
      languages: [],
    },

    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      firstName: (value: string) => (value.length < 2 ? 'First Name must have at least 2 letters' : null),
      lastName: (value: string) => (value.length < 2 ? 'Last Name must have at least 2 letters' : null),
      confirmPassword: (value: string, values: TFormValues) =>
        value !== values.password ? 'Passwords did not match' : null,
      password: (value: string) =>
        /(^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))/.test(value)
          ? null
          : 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character and must be longer than 8 characters',
      phoneNumber: (value: string) =>
        /^\+[0-9]/.test(value)
          ? null
          : 'Type your phone number after your country code without spaces, as the example: +3670123456789',
    },
  });

  const fields = form.values.languages.map((language: LanguageAndLevel, index: number) => (
    <Group key={language.language} mt="xs">
      <TextInput className={classes.languageText} disabled value={language.language ?? ''} />
      <Select
        className={classes.languageSelect}
        radius={'md'}
        placeholder="Select Level"
        data={['Beginner', 'Intermediate', 'Advanced', 'Proficient']}
        {...form.getInputProps(`languages.${index}.level`)}
      />
      <ActionIcon
        color="digiVetColor"
        onClick={() => {
          const temp = languageSelectList.find(
            x => x.value === form.getInputProps(`languages.${index}.language`).value,
          );
          if (temp != undefined) temp.disabled = false;
          form.removeListItem('languages', index);
        }}
      >
        <img src={cancel}></img>
      </ActionIcon>
    </Group>
  ));

  return (
    <>
      {isLoading ? (
        <Center mt={250}>
          <Loader size={'xl'} />
        </Center>
      ) : (
        <Box sx={{ maxWidth: 340 }} mx="auto">
          <Grid mb={20}>
            <Grid.Col span={5}>
              <Text
                sx={{
                  fontSize: '30px',
                }}
                color={'white'}
                mt={'10px'}
              >
                Registration
              </Text>
            </Grid.Col>
            <Grid.Col span={5} offset={2}>
              <Image width={'75%'} src={DigiVetLogo} />
            </Grid.Col>
          </Grid>

          <form
            onSubmit={form.onSubmit(() => {
              console.log(form.values.languages);
              const studentRegistrationApi = new ApiWrapper<StudentRegistrationApiApi>(auth, StudentRegistrationApiApi);
              studentRegistrationApi.load(
                async () => {
                  await studentRegistrationApi.getApiInstance().apiStudentRegistrationPost(
                    params.code ?? '',
                    form.values.email,
                    form.values.password,
                    form.values.firstName,
                    form.values.lastName,
                    form.values.phoneNumber,
                    new Date(form.values.birthDate).toLocaleDateString('en-GB'),
                    form.values.sex,
                    params.userId,
                    form.values.idNumber,
                    form.values.isicNumber,
                    form.values.allergies,
                    form.values.medicines,
                    form.values.diet,
                    form.values.cv[0],
                    form.values.languages.map(language => ({
                      language: GetCodeByLanguageText(language.language),
                      level: language.level,
                    })),
                  );
                },
                () => {
                  navigate('/registration/contactInfo');
                },
                () => {
                  navigate('/registration/contactInfo');
                },
              );
              navigate('/');
            })}
          >
            <TextInput
              className={classes.label}
              disabled
              required
              radius="md"
              label="Email"
              {...form.getInputProps('email')}
            />

            <PasswordInput
              className={classes.label}
              required
              radius="md"
              label="Password"
              {...form.getInputProps('password')}
            />

            <PasswordInput
              className={classes.label}
              required
              radius="md"
              mt="sm"
              label="Confirm password"
              {...form.getInputProps('confirmPassword')}
            />
            <TextInput
              className={classes.label}
              required
              radius="md"
              label="First Name"
              {...form.getInputProps('firstName')}
            />

            <TextInput
              className={classes.label}
              required
              radius="md"
              label="Last Name"
              {...form.getInputProps('lastName')}
            />

            <TextInput
              className={classes.label}
              required
              radius="md"
              label="Phone Number"
              {...form.getInputProps('phoneNumber')}
            />

            <DatePicker
              radius={'md'}
              className={classes.label}
              placeholder="Date of Birth"
              label="Date of Birth"
              required
              {...form.getInputProps('birthDate')}
            />

            <Radio.Group
              className={classes.radioGroup}
              classNames={{ label: classes.radioGroupLabel }}
              required
              color={'digiVetColor'}
              aria-required
              label="Sex"
              {...form.getInputProps('sex')}
            >
              <Radio value="Male" label="male" />
              <Radio value="Female" label="female" />
            </Radio.Group>

            <TextInput
              className={classes.label}
              disabled
              required
              radius="md"
              label="School"
              {...form.getInputProps('school')}
            />

            <TextInput
              className={classes.label}
              disabled
              required
              radius="md"
              label="Profession"
              {...form.getInputProps('profession')}
            />
            <Group align={'self-end'}>
              <Text color={'white'} className={classes.text}>
                {' '}
                Show optional fields
              </Text>
              <Switch
                color="digiVetColor"
                onChange={() => {
                  setChecked(!checked);
                }}
              />
            </Group>

            {checked && (
              <>
                <TextInput
                  className={classes.label}
                  radius="md"
                  label="ID Number"
                  {...form.getInputProps('idNumber')}
                />

                <TextInput
                  className={classes.label}
                  radius="md"
                  label="ISIC Number"
                  {...form.getInputProps('isicNumber')}
                />

                <TextInput
                  className={classes.label}
                  radius="md"
                  label="Allergies"
                  {...form.getInputProps('allergies')}
                />

                <TextInput
                  className={classes.label}
                  radius="md"
                  label="Medicines"
                  {...form.getInputProps('medicines')}
                />

                <TextInput
                  className={classes.label}
                  radius="md"
                  label="Special Diet ( Vegan, Gluten-free etc.)"
                  {...form.getInputProps('diet')}
                />

                <FormDropzone {...form.getInputProps('cv')} />

                {form.values.cv.map((value, index) => (
                  <Group position="center" key={value.name + index}>
                    <Text>{value.name}</Text>
                    <ActionIcon
                      onClick={() => {
                        form.setValues(prev => {
                          const next = { ...prev };
                          next.cv = [];
                          return next;
                        });
                      }}
                    >
                      <Image src={remove}></Image>
                    </ActionIcon>
                  </Group>
                ))}

                <Box sx={{ maxWidth: 500 }} mx="auto">
                  <Select
                    className={classes.label}
                    radius={'md'}
                    placeholder="Add more languages"
                    style={{ marginTop: 20, zIndex: 2 }}
                    value={''}
                    data={languageSelectList}
                    label="Languages"
                    onChange={(value: string) => {
                      const temp = languageSelectList.find(x => x.value === value);
                      if (temp != undefined) temp.disabled = true;
                      form.insertListItem('languages', { language: value, level: ' ' });
                    }}
                  />
                  {fields.length > 0 && fields}
                </Box>
              </>
            )}
            <Center mt={25}>
              <DigiVetButton size="md" type="submit" content="Send"></DigiVetButton>
            </Center>
          </form>
        </Box>
      )}
    </>
  );
}

export default Registration;

import { MIME_TYPES } from '@mantine/dropzone';
import { Group, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { GetInputProps } from '@mantine/form/lib/types';

export function dropzoneChildren() {
  return (
    <Group position="center" spacing="xl" style={{ minHeight: 50, pointerEvents: 'none' }}>
      <div>
        <Text size="xs" inline>
          Please, press here if you want to attach your CV in pdf format
        </Text>
      </div>
    </Group>
  );
}

function FormDropzone({ value, onChange }: ReturnType<GetInputProps<'input'>>) {
  return (
    <Dropzone
      mt={10}
      onDrop={files => {
        const prevState = [...(value as File[])];
        if (prevState.length < 1) {
          const nextState = [...prevState, ...files];
          onChange(nextState);
        }
      }}
      onReject={files => console.log('rejected files', files)}
      accept={[MIME_TYPES.pdf]}
    >
      {dropzoneChildren()}
    </Dropzone>
  );
}
export default FormDropzone;

// const toBase64 = (file: File) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
//   });

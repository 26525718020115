type Prop = {
  url: string;
};

const QuizCard = (prop: Prop) => {
  return (
    <>
      <div>
        <iframe src={prop.url} width="100%" height="800px" frameBorder="0" marginHeight={2} marginWidth={2}>
          Loading…
        </iframe>
      </div>
    </>
  );
};
export default QuizCard;

import { Text, Image, Grid } from '@mantine/core';
import DigiVetLogo from '../../assets/E-Learning/digivet.png';

const ErasmusPlus = () => {
  return (
    <Grid mb={20}>
      <Grid.Col span={5}>
        <Text
          sx={{
            fontSize: '20px',
          }}
          color={'white'}
        >
          Under development...
        </Text>
      </Grid.Col>
      <Grid.Col span={5} offset={2}>
        <Image width={'75%'} src={DigiVetLogo} />
      </Grid.Col>
    </Grid>
  );
};

export default ErasmusPlus;

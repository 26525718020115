import { Grid, Stack, Text, Image } from '@mantine/core';
import { useContext } from 'react';
import { CourseDetailDto } from '../../@api/DigiVet';
import CourseAsset from '../../components/E-Learning/CourseAsset';
import DigiVetLogo from '../../assets/E-Learning/digivet.png';
import { CoursesContext } from '../../components/E-Learning/CoursesProvider';

const CourseView = () => {
  const courseAssets = useContext(CoursesContext);

  return (
    <>
      <Grid mb={20}>
        <Grid.Col span={5}>
          <Text
            sx={{
              fontSize: '35px',
            }}
            color={'white'}
          >
            {courseAssets[0]?.assetsTitle ?? ' '}
          </Text>
        </Grid.Col>
        <Grid.Col span={5} offset={2}>
          <Image width={'75%'} src={DigiVetLogo} />
        </Grid.Col>
      </Grid>
      {courseAssets.map((prop: CourseDetailDto, index: number) => (
        <Stack key={index} mt={10}>
          <CourseAsset
            assetsTitle={prop.assetsTitle ?? ' '}
            quizUrl={prop.quizUrl ?? ''}
            courseAsset={prop.assets ?? []}
            sectionId={index}
          />
        </Stack>
      ))}
    </>
  );
};

export default CourseView;

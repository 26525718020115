import { Button, createStyles, MantineSize } from '@mantine/core';

const useStyles = createStyles(theme => ({
  primary: {
    background: theme.colors.digiVetColor,
    borderRadius: '12px',
  },
  outline: {
    '&:disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0); !important',
      borderColor: 'white !important',
      color: 'white !important',
    },
    borderRadius: '12px',
  },
}));

interface IButtonProp {
  content: string;
  isOutline?: boolean;
  onClickHandler?: () => void;
  type?: 'submit' | undefined;
  size?: MantineSize | undefined;
}
const DigiVetButton = (prop: IButtonProp) => {
  const { classes } = useStyles();

  return (
    <>
      {prop.isOutline ? (
        <Button disabled className={classes.outline} size={prop.size} onClick={prop.onClickHandler}>
          {prop.content}
        </Button>
      ) : (
        <Button type={prop.type} className={classes.primary} size={prop.size} onClick={prop.onClickHandler}>
          {prop.content}
        </Button>
      )}
    </>
  );
};

export default DigiVetButton;

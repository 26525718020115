import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CoursesContext } from '../../components/E-Learning/CoursesProvider';
import ImageCard from '../../components/E-Learning/Cards/ImageCard';
import PdfCard from '../../components/E-Learning/Cards/PdfCard';
import TextCard from '../../components/E-Learning/Cards/TextCard';
import VideoCard from '../../components/E-Learning/Cards/VideoCard';
import YoutubeCard from '../../components/E-Learning/Cards/YoutubeCard';
import { Title } from '@mantine/core';

const CourseDetail = () => {
  const courseAssets = useContext(CoursesContext);
  const params = useParams();
  const asset = courseAssets.at(Number(params.sectionId))?.assets?.at(Number(params.assetId));

  if (asset?.url) {
    return (
      <>
        {(asset.url.includes('youtube') || asset.url.includes('youtu.be')) && (
          <YoutubeCard title={asset.title ?? ' '} url={asset?.url} description={asset.description ?? ''} />
        )}
      </>
    );
  }
  if (asset?.text) {
    return <TextCard text={asset.text} description={asset.description ?? ''} title={asset.description ?? ''} />;
  }
  if (asset?.path?.endsWith('.mp4')) {
    return <VideoCard path={asset?.path ?? ' '} title={asset.title ?? ''} description={asset.description ?? ''} />;
  }
  if (asset?.path?.endsWith('.pdf')) {
    return <PdfCard path={asset?.path ?? ' '} />;
  }

  if (asset?.path?.endsWith('.png') || asset?.path?.endsWith('.jpg')) {
    return <ImageCard path={asset?.path ?? ' '} title={asset.title ?? ''} description={asset.description ?? ''} />;
  }

  return (
    <>
      <Title align="center">File is Corrupted</Title>
    </>
  );
};

export default CourseDetail;

import { Text } from '@mantine/core';

interface IDetailData {
  label: string;
  values: string[];
}

const LabelAndValue = (prop: IDetailData) => {
  return (
    <>
      <Text color={'white'} size="md">
        {prop.label}
      </Text>
      {prop.values.map((value, i) => (
        <Text key={i.toString()} size="xl" color={'white'} weight={500} mb={10}>
          {value}
        </Text>
      ))}
    </>
  );
};

export default LabelAndValue;

import { useAuth } from 'react-oidc-context';
import { useEffect, useState } from 'react';
import { MyProfileApiApi } from '../../@api/DigiVet';
import { ApiWrapper } from '../../common/ApiWrapper';
import { Center, Loader, Stack, Text } from '@mantine/core';
import ProfileDetail, { ProfileDetailData } from '../../components/Profile/ProfileDetail';
import ProfileHeader from '../../components/Profile/ProfileHeader';

const MyProfile = () => {
  const auth = useAuth();
  const noValue = '-';
  const defaultData: ProfileDetailData = {
    name: noValue,
    dateOfBirth: noValue,
    gender: noValue,
    school: noValue,
    profession: noValue,
    languages: [noValue],
    isicNumber: noValue,
  };
  const [profileData, setProfileData] = useState(defaultData);
  const [loadState, setLoadState] = useState('loading');

  useEffect(() => {
    const loadProfile = async () => {
      const myProfileApi = new ApiWrapper<MyProfileApiApi>(auth, MyProfileApiApi);
      await myProfileApi
        .load(async () => {
          const profile = await myProfileApi.getApiInstance().apiMyProfileGet();
          setProfileData({
            name: profile.data.name ?? noValue,
            dateOfBirth: profile.data.dateOfBirth
              ? new Date(profile.data.dateOfBirth).toLocaleDateString('en-GB')
              : noValue,
            gender: profile.data.gender ?? noValue,
            school: profile.data.school ?? noValue,
            profession: profile.data.profession ?? noValue,
            isicNumber: profile.data.isicNumber ?? noValue,
            languages:
              profile.data.languages && profile.data.languages.length > 0
                ? profile.data.languages.map(lang => lang.name ?? noValue)
                : [noValue],
          });
        })
        .then(() => setLoadState('loaded'));
    };
    loadProfile();
  }, []);

  if (loadState === 'loading') {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  return (
    <>
      <ProfileHeader />
      <ProfileDetail {...profileData} />
      <Stack spacing={'xs'} mt={200}>
        <Text variant="link" component="a" href="/" color={'gray.0'} size="xs" underline>
          The Privacy Policy
        </Text>
        <Text variant="link" component="a" href="/" color={'gray.0'} size="xs" underline>
          Terms of use
        </Text>
      </Stack>
    </>
  );
};

export default MyProfile;

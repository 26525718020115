import { Title, Stack, Modal, Container, TextInput } from '@mantine/core';
import { useValidatedState } from '@mantine/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DigiVetButton from '../../components/DigiVetButton';

const RegistrationNotFound = () => {
  const [opened, setOpened] = useState(false);
  const [{ value, valid }, setEmail] = useValidatedState('', val => /^\S+@\S+$/.test(val));
  const navigate = useNavigate();
  return (
    <Container>
      <Stack align={'center'}>
        <Title order={3}>Oops! Seems something is wrong, please contact our staff to solve the issue! </Title>
        <TextInput
          value={value}
          onChange={event => setEmail(event.currentTarget.value)}
          required
          error={!valid}
          placeholder="email@example.com"
          label="Your email"
        />{' '}
        <Modal
          opened={opened}
          onClose={() => {
            setOpened(false);
            navigate('/');
          }}
          title="Stay tuned, we'll get back to you soon!"
        />
        <DigiVetButton
          content="Contact me"
          onClickHandler={() => {
            if (valid) {
              setOpened(true);
            }
          }}
        ></DigiVetButton>
      </Stack>
    </Container>
  );
};
export default RegistrationNotFound;

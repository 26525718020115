import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';

const baseUrl = window.location.origin;
const oidcConfig = {
  authority: baseUrl + '/cms',
  client_id: '8347af4e-9e4a-4925-8444-4739b27b5109',
  redirect_uri: baseUrl + '/login-callback',
  post_logout_redirect_uri: baseUrl + '/logout-callback',
} as AuthProviderProps;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MantineProvider
      theme={{
        fontFamily: 'Lato, sans-serif',
        fontFamilyMonospace: 'Lato, sans-serif',
        headings: { fontFamily: 'Lato, sans-serif' },
        globalStyles: () => ({
          body: {
            height: '100%',
            minHeight: '900px',
            background:
              'linear-gradient(180deg,rgba(237, 188, 109, 1) 0%,rgba(111, 102, 169, 1) 87%,rgba(255, 255, 255, 1) 100%,rgba(0, 0, 0, 1) 100%)',
            backgroundRepeat: 'no-repeat',
          },
        }),
        colors: {
          badgeColor: [
            '#FBFBFB',
            '#FBFBFB',
            '#FBFBFB',
            '#FBFBFB',
            '#FBFBFB',
            '#FBFBFB',
            '#FBFBFB',
            '#FBFBFB',
            '#FBFBFB',
          ],
          digiVetColor: [
            '#494ADA',
            '#494ADA',
            '#494ADA',
            '#494ADA',
            '#494ADA',
            '#494ADA',
            '#494ADA',
            '#494ADA',
            '#494ADA',
          ],
        },
      }}
    >
      <BrowserRouter>
        <AuthProvider {...oidcConfig}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>,
);

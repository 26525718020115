import { AuthContextProps } from 'react-oidc-context';
import { Configuration } from '../@api/DigiVet';
import { isAxiosErrorTypeGuard } from './type-guards';
import { BaseAPI } from '../@api/DigiVet/base';
import { AxiosError } from 'axios';

export class ApiWrapper<ApiType extends BaseAPI> {
  private readonly auth: AuthContextProps;
  private readonly config: Configuration;
  private readonly apiInstance: ApiType;

  constructor(auth: AuthContextProps, ApiType: new (config?: Configuration) => ApiType) {
    this.auth = auth;
    this.config = this.initConfig();
    this.apiInstance = new ApiType(this.config);
  }

  private initConfig(): Configuration {
    const accessToken = this.auth.user?.access_token;
    const config = new Configuration();
    config.accessToken = accessToken;
    return config;
  }

  public getApiInstance() {
    return this.apiInstance;
  }

  public async load(
    apiCaller: () => void,
    errorResponseAction?: (e: AxiosError) => void,
    noResponseAction?: (e: AxiosError) => void,
  ) {
    try {
      await apiCaller();
    } catch (e: unknown) {
      if (isAxiosErrorTypeGuard(e)) {
        // Handle Axios specific errors.
        if (e.response) {
          console.log(
            `Failed response for ${this.apiInstance.constructor.name} API, HTTP client message: ${e.message}.`,
          );
          errorResponseAction && errorResponseAction(e);
        } else if (e.request) {
          console.log(`Failed to communicate with backend, no response.`);
          noResponseAction && noResponseAction(e);
        } else {
          console.log(`Unknown API request error ${JSON.stringify(e)}`);
        }
      } else {
        console.log('Unknown API client error.');
        throw e;
      }
    }
  }
}

import { Card, Text } from '@mantine/core';
import './YoutubeStyle.css';

type Prop = {
  url: string;
  title: string;
  description: string;
};

const YoutubeCard = (prop: Prop) => {
  const embeddedUrl = 'https://www.youtube.com/embed/' + getVideoId(prop.url);
  return (
    <>
      <Card shadow="sm" p="lg" radius="md" withBorder mt={30}>
        <Card.Section mt={5}>
          <div className="video-responsive">
            <iframe
              width="853"
              height="480"
              src={embeddedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </Card.Section>
        <Text weight={800}>{prop.title}</Text>
        <Text size="sm" color="dark">
          {prop.description}
        </Text>
      </Card>
    </>
  );
};

function getVideoId(inputString: string): string | null {
  // Check if URL is from youtu.be
  const shortUrlMatch = inputString.match(/youtu\.be\/([a-zA-Z0-9_-]+)/);
  if (shortUrlMatch) {
    return shortUrlMatch[1];
  }

  // Check if URL is an embedded URL
  const embedUrlMatch = inputString.match(/youtube\.com\/embed\/([a-zA-Z0-9_-]+)/);
  if (embedUrlMatch) {
    return embedUrlMatch[1];
  }

  // Check for standard watch URL by looking for 'v' query parameter
  const queryParams = new URLSearchParams(inputString.split('?')[1]);
  if (queryParams.has('v')) {
    return queryParams.get('v');
  }

  // If no known pattern matches, return null
  return null;
}
export default YoutubeCard;

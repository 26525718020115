import { Affix, ActionIcon } from '@mantine/core';
import { Logout } from 'tabler-icons-react';
import { useAuth } from 'react-oidc-context';
const LogOut = () => {
  const auth = useAuth();

  function handleLogout() {
    auth.signoutRedirect();
  }

  return (
    <>
      <Affix position={{ top: 10, right: 20 }}>
        <ActionIcon onClick={handleLogout}>
          <Logout />
        </ActionIcon>
      </Affix>
    </>
  );
};
export default LogOut;
